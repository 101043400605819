import Slider from 'react-slick';
import {
  SlideMain,
  SlideMainMobile,
  SlideSeaFreight,
  SlideSeaFreightMobile
} from './asset-constants';

const imageList: {
  image: string;
  uri: string;
  mobile: string;
  uris?: string[];
}[] = [
  {
    image: SlideMain,
    mobile: SlideMainMobile,
    uri: '#drop-off-hubs',
    uris: ['https://onelink.to/jzcdu2', '#drop-off-hubs']
  },
  {
    image: SlideSeaFreight,
    mobile: SlideSeaFreightMobile,
    uri: 'https://ship.topship.africa'
  }
];

const isMobile = window.innerWidth <= 768;

export default function HomeCarousel() {
  return (
    <div className='banner-container'>
      <Slider dots autoplay speed={500} slidesToShow={1} slidesToScroll={1}>
        {imageList.map((slide, index) => (
          <div key={index} className='banner-item'>
            <img
              onClick={event => {
                const percentageY = Math.floor(
                  (event.nativeEvent.offsetY /
                    (event.target as any).clientHeight) *
                    100
                );

                const percentageX = Math.floor(
                  (event.nativeEvent.offsetX /
                    (event.target as any).clientWidth) *
                    100
                );

                if (isMobile) {
                  if (index === 0) {
                    if (
                      percentageY >= 60 &&
                      percentageY < 75 &&
                      percentageX >= 5 &&
                      percentageX < 60
                    ) {
                      return window.open(slide.uris?.[0]);
                    }

                    if (
                      percentageY >= 85 &&
                      percentageY < 90 &&
                      percentageX >= 5 &&
                      percentageX < 50
                    ) {
                      return window.location.replace(
                        `${window.location.origin}/${slide.uris?.[1]}`
                      );
                    }
                  } else {
                    if (
                      percentageY >= 60 &&
                      percentageY < 80 &&
                      percentageX >= 5 &&
                      percentageX < 50
                    ) {
                      return window.open(slide.uri);
                    }
                  }
                } else {
                  if (index === 0) {
                    if (
                      percentageY >= 75 &&
                      percentageY < 85 &&
                      percentageX >= 25 &&
                      percentageX < 50
                    ) {
                      return window.location.replace(
                        `${window.location.origin}/${slide.uri}`
                      );
                    }
                  } else {
                    if (
                      percentageY >= 65 &&
                      percentageY < 85 &&
                      percentageX >= 5 &&
                      percentageX < 20
                    ) {
                      return window.open(slide.uri);
                    }
                  }
                }
              }}
              src={isMobile ? slide.mobile : slide.image}
              alt='ad banner'
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
