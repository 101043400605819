import axios from "axios";
// import { useState, useEffect } from "react";

const getPlayLists = async () => {
  const YOUTUBE_PLAYLIST_ITEMS_API =
    "https://www.googleapis.com/youtube/v3/playlistItems";
  try {
    const playList = await axios.get(
      `${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&playlistId=PLQyw6_SoJ0v1brOPUMVglQ6cEOG-hw6QG&key=AIzaSyDGFG8v8QiInhSvH1dxWKb-gxSMZflvARo`
    );

    const playListItems = playList.data.items;
    // console.log(playListItems);
    return playListItems;
  } catch (error) {
    return [];
  }
};

const getPosts = async () => {
  try {
    const blogPosts = await axios.get(
      `${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/posts?per_page=3`
    );

    if (blogPosts.data) {
      return blogPosts.data;
    }
  } catch (error) {
    console.log("posts", error.response);
    return [];
  }
};

export { getPlayLists, getPosts };
