import React, { useState } from 'react';
import { useQuery } from 'react-query';

import {
  Market,
  PeaceIbadin,
  Rendoll,
  ScentsAndSpirits,
  Uyaima,
  YoutubeIcon
} from '../constants/asset-constants';
import { getPlayLists } from '../services';
import { defaultTestimonialData } from './new-home-page/constants';

const Testimonials = () => {
  const [playlistInfo, setPlaylistInfo] = useState();
  const [selectedPlaylist, setSelectedPlaylist] = useState(false);

  const toggleSelectedPlaylistIndicator = () => {
    setSelectedPlaylist(!selectedPlaylist);
  };

  const handlePlaylistInfo = playlist => () => {
    setPlaylistInfo(playlist);
    toggleSelectedPlaylistIndicator();
  };

  const { data: playlistData } = useQuery('playlists', getPlayLists);

  const playlistDescription =
    playlistInfo?.snippet?.description.split(' - ')[0];

  const playlistAuthor = `${
    playlistInfo?.snippet?.description.split(' - ')[1].split(',')[0]
  } - ${playlistInfo?.snippet?.description.split(' - ')[1].split(',')[1]}`;

  const playlistAuthorCompany = playlistInfo?.snippet?.description
    .split(' - ')[1]
    .split(',')[2];

  const renderYoutubeImage = index => {
    switch (index) {
      case 0:
        return Rendoll;
      case 1:
        return ScentsAndSpirits;
      case 2:
        return PeaceIbadin;
      case 3:
        return Market;
      case 4:
        return Uyaima;
      default:
        return Rendoll;
    }
  };

  return (
    <section className='new-home-page__testimonial'>
      <div className='homepage-container'>
        <div className='testimonial-contents'>
          <div className='testimonial-content-video'>
            <img
              alt={playlistAuthorCompany || 'playlist video'}
              className='img-fluid testimonial-image'
              src={renderYoutubeImage(
                playlistInfo
                  ? playlistData?.findIndex(
                      playlist => playlist.id === playlistInfo?.id
                    )
                  : 0
              )}
            />
            <button
              onClick={() =>
                window
                  .open(
                    `https://www.youtube.com/watch?v=${
                      playlistInfo
                        ? playlistInfo?.snippet?.resourceId?.videoId
                        : defaultTestimonialData.videoId
                    }`,
                    '_blank'
                  )
                  .focus()
              }
            >
              <span>Watch on YouTube</span>{' '}
              <img src={YoutubeIcon} className='img-fluid' alt='' />
            </button>
          </div>
          <div className='testimonial-content-details'>
            <div className='testimonial-details'>
              <p className='testimonial-caption'>
                Loved & trusted by Small and Medium Businesses in Nigeria
              </p>
              <div className='testimonial-merchant-contents'>
                <p className='details testimonial-description '>
                  {playlistInfo
                    ? playlistDescription.replace('.', '')
                    : defaultTestimonialData.description.replace('.', '')}
                </p>
                <p className='testimonial-author'>
                  {playlistInfo
                    ? playlistAuthor.replace('.', '')
                    : defaultTestimonialData.author.replace('.', '')}
                </p>
                <p className='testimonial-company'>
                  {playlistInfo
                    ? playlistAuthorCompany.replace('.', '')
                    : defaultTestimonialData.company.replace('.', '')}
                </p>
              </div>
            </div>
            <div className='testimonial-selectors'>
              <div id='testimonial-carousel-selectors'>
                {playlistData?.map((playlist, playlistIndex) => {
                  const upperIndex = playlistInfo
                    ? playlistData.findIndex(
                        playlist => playlist.id === playlistInfo.id
                      ) + 2
                    : 2;
                  return (
                    <div
                      key={playlist.id}
                      className={
                        (!playlistInfo && playlistIndex === 0) ||
                        playlistInfo?.id === playlist.id
                          ? 'testimonial-carousel-selector'
                          : 'testimonial-carousel-not-selected'
                      }
                      style={{
                        opacity: playlistIndex >= upperIndex ? '0.2' : ''
                      }}
                      onClick={handlePlaylistInfo(playlist)}
                    ></div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
