import React from 'react';
import NewFooter from '../components/new-footer';
import {
  PluginImg1,
  Shopify,
  Wix,
  Woo,
  WordPress,
  SquareSpace,
  GlobeImg,
  ReferBusiness,
  SupportImg,
  EComm,
  Creators,
  MediumBusiness
} from '../constants/asset-constants';

export default function Plugin() {
  return (
    <>
      <div className='plugin'>
        <section className='plugin__merchants'>
          <div className='merchants-content'>
            <div className='tag'>For Merchants</div>
            <h1 className='header'>
              Say goodbye to booking customer deliveries manually!
            </h1>
            <p>
              Install the Topship Plug-in to automatically ship products to
              customers as soon as they pay and checkout on your eCommerce
              website. No coding is required. Easy to use. Get set up in
              minutes.
            </p>
          </div>

          <div className='image-container'>
            <img src={PluginImg1} alt='developer-img' />
          </div>
        </section>
        <section className='plugin__integrations'>
          <div className='integration-content'>
            <h1 className='header'>Integrations</h1>
            <div className='card-wrapper'>
              <div className='card-content'>
                <img className='woo' src={Woo} alt='' />
                <h5 className='woo-header'>Woocommerce</h5>
                <p>A customizable open-source platform built.</p>

                <a href='https://topship.s3.eu-west-2.amazonaws.com/TopshipAfrica.zip'>
                  <button className='active-button'>Install plugin</button>
                </a>
              </div>

              <div className='card-content'>
                <img className='wordpress' src={WordPress} alt='' />
                <h5>Wordpress</h5>
                <p>
                  An open source software you can use to create a beautiful
                  website, blog, or app.
                </p>

                <a href='https://topship.s3.eu-west-2.amazonaws.com/TopshipAfrica.zip'>
                  <button className='active-button'>Install plugin</button>
                </a>
              </div>

              <div className='card-content'>
                <img src={Shopify} alt='' />
                <h5>Shopify</h5>
                <p>
                  The all-in-one commerce platform to start, run, and grow a
                  business
                </p>

                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://form.typeform.com/to/klclH8qF'
                >
                  <button className='disabled-button'>Join Waitlist</button>
                </a>
              </div>

              <div className='card-content'>
                <img src={Wix} alt='' />
                <h5>Wix</h5>
                <p>
                  A website builder that allows you to build your unique online
                  presence.
                </p>

                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://form.typeform.com/to/klclH8qF'
                >
                  <button className='disabled-button'>Join Waitlist</button>
                </a>
              </div>

              <div className='card-content'>
                <img src={SquareSpace} alt='' />
                <h5>Squarespace</h5>
                <p>A powerful e-commerce website builder for businesses.</p>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://form.typeform.com/to/klclH8qF'
                >
                  <button className='disabled-button'>Join Waitlist</button>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='plugin__shipping'>
          <div className='image-container'>
            <img src={GlobeImg} alt='custom-img' />
          </div>

          <div className='shipping-content'>
            <h1 className='header'>
              Ship to customers anywhere <br />
              in the world
            </h1>
            <p>
              Doorstep delivery to any location in Nigeria, USA, UK, Ghana,
              Dubai, and 200+ cities. Next-day, express, and cargo (10kg+)
              delivery options are available at great rates!
            </p>
            <a
              rel='noreferrer'
              target='_blank'
              href='https://api-topship.com/shipping/docs#/'
              className='access-button'
            >
              Access Documentation
            </a>
          </div>
        </section>

        <section className='api-documentation__refer-business'>
          <div className='refer-content'>
            <h1 className='header'>Tracking and Visibility</h1>
            <p>
              Keep track of your deliveries with the Topship dashboard. Live
              status updates are sent to the dashboard daily.
            </p>
            <a
              rel='noreferrer'
              target='_blank'
              href='https://api-topship.com/shipping/docs#/'
              className='access-button'
            >
              Access Documentation
            </a>
          </div>

          <div className='image-container'>
            <img src={ReferBusiness} alt='refer-img' />
          </div>
        </section>

        <section className='api-documentation__slack-support'>
          <div className='image-container'>
            <img src={SupportImg} alt='custom-img' />
          </div>

          <div className='support-content'>
            <h1 className='header'>Support</h1>
            <p>
              Dedicated Slack support channel for escalations and technical
              assistance. Get updates and responses from our operations and
              developer teams.
            </p>
            <a
              rel='noreferrer'
              target='_blank'
              href='https://api-topship.com/shipping/docs#/'
              className='access-button'
            >
              Access Documentation
            </a>
          </div>
        </section>

        <section className='api-documentation__great-for'>
          <h1 className='header'>Great for </h1>

          <div className='card-div'>
            <div className='card-content'>
              <img src={EComm} alt='' />
              <h5>E-commerce brands</h5>
            </div>
            <div className='card-content'>
              <img src={MediumBusiness} alt='' />
              <h5>
                Small & Medium <br /> Businesses
              </h5>
            </div>

            <div className='card-content'>
              <img src={Creators} alt='' />
              <h5>Creators</h5>
            </div>
          </div>
        </section>
      </div>
      <section className='new-home-page__footer'>
        <NewFooter />
      </section>
    </>
  );
}
