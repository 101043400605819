import React, { useEffect, useMemo, useState } from 'react';
import FaqBlock from '../components/faq-block';
import NewFooter from '../components/new-footer';
import GetMobileApp from '../components/new-home-page/get-mobile-app';
import {
  DropdownIcon,
  LocationIcon,
  PhoneIcon,
  StarIcon
} from '../constants/asset-constants';
import { importsquestions } from '../constants/faqs';
import { ContactForm } from '../components/contact-form';
import { useLocation } from 'react-router-dom';
import { directoryPerCountries } from '../components/new-home-page/constants';

const BrokerDirectoryPage = () => {
  const [options, setOptions] = useState({
    country: '',
    toggleDropdown: false
  });
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const countryParams = params.get('country');
  const brokersPerCountry: {
    tel: string;
    tel2?: string;
    location?: string;
    brokerName: string;
  }[] = useMemo(() => {
    const countryData = directoryPerCountries.find(c =>
      c.country.includes(options.country)
    );
    return countryData ? countryData.brokers : [];
  }, [options.country]);

  useEffect(() => {
    if (countryParams) {
      const currentCounty = directoryPerCountries.find(c =>
        c.country.includes(countryParams)
      );
      if (currentCounty) {
        setOptions(options => ({
          ...options,
          country: currentCounty.country
        }));
      }
    } else {
      setOptions(options => ({
        ...options,
        country: directoryPerCountries[0].country
      }));
    }
  }, [countryParams]);
  return (
    <>
      <div className='exports'>
        <section className='exports__flexible-options'>
          <div
            style={{
              width: '100%'
            }}
            className='intro-wrap'
          >
            <h2 className='heading'>Directory: Customs Clearance Brokers</h2>
            <p
              className='broker-text'
              style={{
                whiteSpace: 'initial',
                margin: '0 auto'
              }}
            >
              Please find below a directory of customs clearance brokers that
              you can contact for assistance. However, please note that Topship
              cannot be held liable for any issues that may arise during the
              clearance process. Need us to manage this process on your behalf?
              Email us at hello@topship.africa or call 02013302594.
            </p>
          </div>
        </section>
        <section className='exports__package-options'>
          <div>
            <p
              className='mb-2 '
              style={{
                textAlign: 'center',
                width: 'auto',
                fontSize: '18px',
                fontWeight: 600
              }}
            >
              Select Country
            </p>
            <div>
              <div className='broker-wrap'>
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 500
                  }}
                >
                  {options.country}{' '}
                </span>
                <img
                  onClick={() =>
                    setOptions(options => ({
                      ...options,
                      toggleDropdown: !options.toggleDropdown
                    }))
                  }
                  className='faq-item-accordion-button'
                  src={DropdownIcon}
                  style={{
                    width: '20px',
                    transform: options.toggleDropdown
                      ? 'rotate(180deg)'
                      : 'rotate(0deg)',
                    transition: 'transform 0.3s ease-in-out',
                    cursor: 'pointer'
                  }}
                  alt='accordion toggle button'
                />
                {options.toggleDropdown && (
                  <div className='country-popup'>
                    {directoryPerCountries.map((c, i) => {
                      return (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setOptions(prev => ({
                              ...prev,
                              country: c.country,
                              toggleDropdown: false
                            }));
                          }}
                          key={i}
                        >
                          <span>{c.country} </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='mt-3'>
            <h2
              className='mb-2 title'
              style={{
                textAlign: 'center',
                fontSize: '24px',
                width: 'auto',
                textTransform: 'uppercase'
              }}
            >
              CUSTOMS BROKERS IN {options.country.replace(/🇺🇬|🇨🇲|🇨🇮/g, '')}
            </h2>
            <div className='broker-container '>
              {brokersPerCountry.map(broker => {
                return (
                  <div key={broker.brokerName} className='broker-item'>
                    <img className='mb-3' src={StarIcon} alt='' />
                    <p>{broker.brokerName}</p>
                    {broker.location && (
                      <div
                        className='mt-3 mb-3'
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start'
                        }}
                      >
                        <img src={LocationIcon} alt='' />

                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                            broker.location
                          )}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <span>{broker.location}</span>
                        </a>
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex'
                      }}
                    >
                      <div>
                        <img src={PhoneIcon} alt='' />
                        <a href={`tel:${broker.tel}`}>
                          <span>{broker.tel}</span>
                        </a>
                      </div>
                      {broker?.tel2 && broker?.tel2 && (
                        <div
                          style={{
                            display: 'flex'
                          }}
                        >
                          <span
                            style={{
                              color: '#000000',
                              padding: '0 10px'
                            }}
                          >
                            or
                          </span>
                          <div>
                            <img src={PhoneIcon} alt='' />
                            <a href={`tel:${broker.tel}`}>
                              <span>{broker?.tel2}</span>
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <ContactForm serviceTitle={'Broker Directory'} showText={false} />
        <FaqBlock questions={importsquestions} />
        <GetMobileApp />
        <section className='new-home-page__footer'>
          <NewFooter />
        </section>
      </div>
    </>
  );
};

export default BrokerDirectoryPage;
