import { useFormik } from 'formik';
import { useSendAlert } from '../utilities/send-alert';
import * as yup from 'yup';
import { parseGraphqlError } from '../utilities/parse-graphql-error';
import axios from 'axios';
import { SupportIcon } from '../constants/asset-constants';

export const ContactForm = ({ serviceTitle, showText = true }) => {
  const sendAlert = useSendAlert();
  const SLACK_URL = process.env.REACT_APP_SLACK_API;

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,

    resetForm
  } = useFormik({
    initialValues: {
      phoneNumber: '',
      firstName: '',
      question: ''
    },
    validationSchema: yup.object().shape({
      phoneNumber: yup.number().required('Please enter a phone number'),
      firstName: yup.string().required('Please enter a name'),
      question: yup.string().required('Please enter your question')
    }),
    onSubmit: async values => {
      try {
        const response = await axios.post(SLACK_URL, {
          text: `First Name: ${values.firstName}\nPhone Number: ${values.phoneNumber}\nQuestion: ${values.question}\nService: ${serviceTitle}`,
          channel: 'merchant-acquisition'
        });

        if (response.data) {
          sendAlert(
            'Question submitted successfully. We would contact you shortly!',
            'success'
          );
          resetForm();
        }
      } catch (error) {
        sendAlert(parseGraphqlError(error), 'error');

        throw error;
      }
    }
  });
  return (
    <div id='contact-form' className='get-help-form'>
      <div className='get-help-form__contact-form'>
        <div className='lets-chat'>
          <h3 className='heading mx-0 mb-2'>Let's Talk</h3>
          <p className='paragraph'>
            Need answers or assistance, we're here to help you out
          </p>
          <div className='mt-4'>
            <a
              style={{
                textDecoration: 'none'
              }}
              href='mailto: hello@topship.africa'
            >
              <div className='new-home-page__link-button bttn-sm bttn-blue contact-support'>
                <img
                  style={{
                    paddingRight: '4px'
                  }}
                  src={SupportIcon}
                  alt=''
                />
                Contact Support
              </div>
            </a>
          </div>
        </div>
        <div className='form-container'>
          <form onSubmit={handleSubmit}>
            <div className='form-row'>
              <div className='form-group'>
                <label htmlFor='firstName'>Name</label>
                <input
                  type='text'
                  id='firstName'
                  name='firstName'
                  value={values.firstName}
                  placeholder='Enter name'
                  onChange={handleChange}
                />
                {touched.firstName && errors.firstName && (
                  <span className='validation-error text-left footer-error-message'>
                    {errors.firstName}
                  </span>
                )}
              </div>
              <div className='form-group'>
                <label htmlFor='phoneNumber'>Phone Number</label>
                <input
                  type='number'
                  id='phoneNumber'
                  name='phoneNumber'
                  value={values.phoneNumber}
                  placeholder='Enter phone number'
                  onChange={handleChange}
                />
                {touched.phoneNumber && errors.phoneNumber && (
                  <span className='validation-error text-left footer-error-message'>
                    {errors.phoneNumber}
                  </span>
                )}
              </div>
            </div>
            <div className='form-group mt-1'>
              <label htmlFor='question'>How can we help you?</label>
              <textarea
                id='question'
                name='question'
                rows='4'
                placeholder='Enter question'
                value={values.question}
                onChange={handleChange}
              ></textarea>
              {touched.question && errors.question && (
                <span className='validation-error text-center footer-error-message'>
                  {errors.question}
                </span>
              )}
            </div>
            <button
              style={{
                width: '100%'
              }}
              className='mt-2 new-home-page__link-button bttn-sm bttn-blue'
              type='submit'
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
